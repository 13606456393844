/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TypePolicy, TypePolicies } from '@apollo/client';

import { WeightDisplay } from '#generated-types';
import { convertWeightForDisplay } from './utils/convertWeightForDisplay';

const calculateNetWeight = (
  grossWeight: number | null,
  emptyContainerWeight: number | null,
  display: WeightDisplay,
): number | null => {
  if (grossWeight === null || grossWeight === undefined) {
    return null;
  }

  return display === WeightDisplay.Gross
    ? grossWeight
    : Math.max(grossWeight - (emptyContainerWeight || 0), 0);
};

const ContainerCycle: TypePolicy = {
  fields: {
    emptyContainerWeight: {
      read(weight, { args }) {
        return convertWeightForDisplay(weight, args!.unit);
      },
    },
    finalDisplayWeight: {
      read(_, { variables, args, readField }) {
        const weight = readField<number | null>({
          fieldName: 'grossWeight',
          args: { unit: variables!.unit },
          from: readField('finalWeight'),
        });

        const emptyContainerWeight = readField<number | null>({
          fieldName: 'emptyContainerWeight',
          args: { unit: variables!.unit },
        });

        return calculateNetWeight(
          weight!,
          emptyContainerWeight!,
          args!.display,
        );
      },
    },
  },
};

const ContainerSite: TypePolicy = {
  fields: {
    emptyContainerWeight: {
      read(weight, { args }) {
        return convertWeightForDisplay(weight, args!.unit);
      },
    },
    currentGrossWeight: {
      read(weight, { args }) {
        return convertWeightForDisplay(weight, args!.unit, true);
      },
    },
    currentNetWeight: {
      read(_, { variables, args, readField }) {
        const currentGrossWeight = readField<number | null>({
          fieldName: 'currentGrossWeight',
          args: { unit: variables!.unit },
        });

        const emptyContainerWeight = readField<number | null>({
          fieldName: 'emptyContainerWeight',
          args: { unit: variables!.unit },
        });

        return calculateNetWeight(
          currentGrossWeight!,
          emptyContainerWeight!,
          args!.display,
        );
      },
    },
    maxGrossWeight: {
      read(weight, { args }) {
        return convertWeightForDisplay(weight, args!.unit);
      },
    },
    maxNetWeight: {
      read(_, { variables, args, readField }) {
        const maxGrossWeight = readField<number | null>({
          fieldName: 'maxGrossWeight',
          args: { unit: variables!.unit },
        });

        const emptyContainerWeight = readField<number | null>({
          fieldName: 'emptyContainerWeight',
          args: { unit: variables!.unit },
        });

        return calculateNetWeight(
          maxGrossWeight!,
          emptyContainerWeight!,
          args!.display,
        );
      },
    },
    currentDisplayWeight: {
      read(_, { variables, args, readField }) {
        return args!.display === WeightDisplay.Gross
          ? readField<number | null>({
              fieldName: 'currentGrossWeight',
              args: { unit: variables!.unit },
            })
          : readField<number | null>({
              fieldName: 'currentNetWeight',
              args: { unit: variables!.unit },
            });
      },
    },
    maxDisplayWeight: {
      read(_, { variables, args, readField }) {
        return args!.display === WeightDisplay.Gross
          ? readField<number | null>({
              fieldName: 'maxGrossWeight',
              args: { unit: variables!.unit },
            })
          : readField<number | null>({
              fieldName: 'maxNetWeight',
              args: { unit: variables!.unit },
            });
      },
    },
  },
};

const FirmwareConfig: TypePolicy = {
  keyFields: false,
};

const UserPhone: TypePolicy = {
  fields: {
    formattedNumber: {
      read(_, { readField }) {
        const number: string | undefined = readField('number');

        if (!number || number.length !== 12) {
          return null;
        }

        const countryCode = number.substring(0, 2);
        const areaCode = number.substring(2, 5);
        const prefix = number.substring(5, 8);
        const lineNumber = number.substring(8);

        return `${countryCode} ${areaCode} ${prefix}-${lineNumber}`;
      },
    },
  },
};

const WeightPost: TypePolicy = {
  fields: {
    grossWeight: {
      read(weight, { args }) {
        return convertWeightForDisplay(weight, args!.unit, true);
      },
    },
    netWeight: {
      read(weight, { args }) {
        return convertWeightForDisplay(weight, args!.unit, true);
      },
    },
    displayWeight: {
      read(_, { variables, args, readField }) {
        return args!.display === WeightDisplay.Gross
          ? readField<number>({
              fieldName: 'grossWeight',
              args: { unit: variables!.unit },
            })
          : readField<number>({
              fieldName: 'netWeight',
              args: { unit: variables!.unit },
            });
      },
    },
    mlGrossWeight: {
      read(weight, { args }) {
        return convertWeightForDisplay(weight, args!.unit, true);
      },
    },
    mlNetWeight: {
      read(weight, { args }) {
        return convertWeightForDisplay(weight, args!.unit, true);
      },
    },
    mlDisplayWeight: {
      read(_, { variables, args, readField }) {
        return args!.display === WeightDisplay.Gross
          ? readField<number>({
              fieldName: 'mlGrossWeight',
              args: { unit: variables!.unit },
            })
          : readField<number>({
              fieldName: 'mlNetWeight',
              args: { unit: variables!.unit },
            });
      },
    },
  },
};

const WeightTicket: TypePolicy = {
  fields: {
    grossWeight: {
      read(weight, { args }) {
        return convertWeightForDisplay(weight, args!.unit);
      },
    },
    tareWeight: {
      read(weight, { args }) {
        return convertWeightForDisplay(weight, args!.unit);
      },
    },
    netWeight: {
      read(weight, { args }) {
        return convertWeightForDisplay(weight, args!.unit);
      },
    },
  },
};

export const typePolicies: TypePolicies = {
  ContainerCycle,
  ContainerSite,
  FirmwareConfig,
  UserPhone,
  WeightPost,
  WeightTicket,
};
